import produce from 'immer';
import isEqual from 'lodash-es/isEqual';

import { QuotationResultState } from './state.interfaces';
import { ActionType } from 'app/store/actionTypes';
import { QuotationResultActionsType } from './actions';
import { BonusRangeAndPointsInterface } from 'app/shared/interfaces/BonusRangeAndPoints.interface';

const initialState: QuotationResultState = {
  offers: {},
  insurers: {},
  results: {},
  calculationTriggeredByUser: false,
  fullResultsObject: [],
  activeABTests: [],
  previousQuotation: {
    isPreviousQuotationSameDate: false,
    previousQuotationDate: '',
    isPreviousQuotationDateCheck: false,
  },
  recommendedOfferId: '',
  salesRewardBonusAndPoints: [] as BonusRangeAndPointsInterface[],
};

const quotationResult = (
  state: QuotationResultState = initialState,
  action: QuotationResultActionsType
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionType.STORE_RESULTS:
        const { activeABTests, results } = action.payload;

        draft.fullResultsObject = results;
        draft.activeABTests = activeABTests;

        const addInsurer = (insurer: typeof results[0]['insurer']) => {
          if (state.insurers[insurer.id] === undefined) {
            draft.insurers[insurer.id] = insurer;
          }
        };

        const setCar = (car: typeof results[0]['car']) => {
          if (state.car === undefined) {
            draft.car = car;
          }
        };

        const setInsuranceDuration = (
          duration: typeof results[0]['duration']
        ) => {
          if (state.insuranceDuration === undefined) {
            draft.insuranceDuration = duration;
          }
        };

        const addOffer = (offer: typeof results[0]['offers'][0]) => {
          if (state.offers[offer.id] === undefined) {
            draft.offers[offer.id] = offer;
          }
        };

        const setResult = (result: typeof results[0]) => {
          const resultNormalized = {
            ...result,
            offers: result.offers.map((offer) => offer.id),
          };
          if (!isEqual(state.results[resultNormalized.insurer.id], result)) {
            draft.results[resultNormalized.insurer.id] = resultNormalized;
          }
        };

        results.forEach((result) => {
          addInsurer(result.insurer);
          setCar(result.car);
          setInsuranceDuration(result.duration);
          result.offers.forEach(addOffer);
          setResult(result);
        });

        return;
      case ActionType.UPDATE_OFFER:
        draft.offers[action.payload.id] = action.payload;
        return;
      case ActionType.CLEAR_QUOTATION_RESULT:
        return { ...initialState };
      case ActionType.SET_CALCULATION_TRIGGERED_BY_USER:
        draft.calculationTriggeredByUser = action.payload;
        return;
      case ActionType.SET_PREVIOUS_QUOTATION_DATE:
        draft.previousQuotation = action.payload;
        return;
      case ActionType.SET_RECOMMENDED_OFFER_ID:
        draft.recommendedOfferId = action.payload;
        return;
      case ActionType.SET_SALES_REWARD_BONUS_POINTS:
        draft.salesRewardBonusAndPoints = action.payload;
        return;
      default:
        return;
    }
  });

export default quotationResult;
