export enum OfferScopeEnum {
  AcInsuredAmount = 'Ubezpieczenie AC',
  AcMiniInsuredAmount = 'AC Mini',
  AfterAccident = 'Po wypadku',
  AfterFailure = 'Po awarii',
  AfterFailureTooltip = 'Szerszą pomoc na drodze otrzymasz wybierając Assistance rozszerzony o awarię. Mogą obowiązywać inne limity niż w przypadku holowania po wypadku.',
  Aso = 'Serwis autoryzowany',
  Assistance = 'Assistance',
  DistanceLimitTooltip = 'Maksymalny zasięg holowania PO WYPADKU w kilometrach od miejsca zdarzenia do warsztatu, na parking lub do lokalizacji wybranej przez ubezpieczonego - w Polsce (PL) lub Europie (EU). Jeśli podana jest jedna wartość dla EU, oznacza to taki sam limit w Polsce. Dla holowania po awarii mogą obowiązywać inne limity.',
  ExcessValues = 'Udział własny',
  ExcessValuesTooltip = 'Jest to wartość szkody, którą pokrywasz z\xa0własnej kieszeni. Ubezpieczenie z\xa0udziałem własnym np.\xa0500\xa0zł często jest znacznie tańsze.',
  ExcessValuesTooltipTestV6 = 'Zniesienie udziału własnego, przeniesie całą odpowiedzialność za szkody na ubezpieczyciela, a Ty nie zapłacisz minimalnej kwoty z własnej kieszeni.',
  InsuredAmountTooltip = 'To maksymalna kwota, jaką możesz otrzymać za\xa0szkodę. Ustalana jest na\xa0podstawie wartości pojazdu w\xa0dniu zakupu polisy.',
  InsuredAmountTooltipScopeTestV6 = 'Zakres ubezpieczenia wskazuje zdarzenia objęte ochroną.',
  InvoiceType = 'Forma naprawy',
  InvoiceTypeTooltip = 'Kosztorys - pieniądze otrzymujesz na\xa0konto, żeby naprawić samochód we\xa0własnym zakresie. Koszty naprawy wycenia rzeczoznawca. Warsztat, ASO (serwis autoryzowany) -\xa0rozliczenie w\xa0formie bezgotówkowej, samochód odstawiasz do\xa0warsztatu lub ASO, a\xa0rozliczeniem kosztów zajmuje się ubezpieczyciel.',
  InvoiceTypeTooltipMoto = 'Kosztorys - pieniądze otrzymujesz na\xa0konto, żeby naprawić pojazd we\xa0własnym zakresie. Koszty naprawy wycenia rzeczoznawca. Warsztat, ASO (serwis autoryzowany) -\xa0rozliczenie w\xa0formie bezgotówkowej, pojazd odstawiasz do\xa0warsztatu lub ASO, a\xa0rozliczeniem kosztów zajmuje się ubezpieczyciel.',
  NoLimit = 'Bez limitu',
  PartsAmortization = 'Amortyzacja części',
  PartsAmortizationTooltip = 'Amortyzacja oznacza zużywanie się części. Wybierając opcję "0%" pomimo starzenia się auta, części zostaną wycenione na\xa0pełną wartość. W przypadku opcji powyżej 0%, odszkodowanie za\xa0części zostanie pomniejszone o\xa0wskazaną wartość.',
  PartsAmortizationTooltipMoto = 'Amortyzacja oznacza zużywanie się części. Wybierając opcję "0%" pomimo starzenia się pojazdu, części zostaną wycenione na\xa0pełną wartość. W przypadku opcji powyżej 0%, odszkodowanie za\xa0części zostanie pomniejszone o\xa0wskazaną wartość.',
  SubstituteCar = 'Auto zastępcze',
  SubstituteCarTooltip = 'Auto zastępcze przysługuje w\xa0przypadkach opisanych w\xa0ofercie np. po\xa0kolizji. Liczba dni oznacza maksymalny okres na\xa0jaki otrzymasz auto zastępcze.',
  SubstituteVehicle = 'Pojazd zastępczy',
  SubstituteVehicleTooltip = 'Pojazd zastępczy przysługuje w\xa0przypadkach opisanych w\xa0ofercie np. po\xa0kolizji. Liczba dni oznacza maksymalny okres na\xa0jaki otrzymasz pojazd zastępczy.',
  WithoutExcessValues = 'Bez udziału własnego',
}
