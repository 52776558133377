export enum TravelUpSaleEnum {
  Add = 'Dodaj',
  ChangTravelDataForMoreOffers = 'Aby otrzymać więcej ofert, zmień dane swojej podróży',
  ChangeTravelData = 'Zmień dane podróży',
  Collapse = 'Zwiń',
  ExpandMore = 'Rozwiń więcej',
  ForRentCar = 'Dla wynajmujących pojazdy',
  ForSportsMan = 'Dla sportowców',
  InsuranceTrip = 'Ubezpieczenie przejazdu',
  More = 'Więcej',
  NoFoundDescription = 'Niestety nie znaleźliśmy ofert, które spełniają Twoje aktualne wymagania.',
  NoFoundDescription2 = 'Zmień datę startu na późniejszą lub dostosuj inne kryteria, aby zobaczyć dostępne propozycje.',
  NoFoundTitle = 'Ups! Nic nie znaleźliśmy…',
  NoOfferResults = 'Dla wybranych kryteriów podróży oferta Ubezpieczyciela jest niedostępna',
  NoThanks = 'Nie, dziękuję',
  RecommendToYou = 'Polecane dla Ciebie',
  Remove = 'Usuń',
  WhatIsIncluded = 'Co obejmuje ubezpieczenie?',
}
