import { ActionsUnion, createAction } from 'app/store/helpers';
import {
  OfferUpdateActionPayload,
  ResultsStoreActionPayload,
} from './actionPayload.interfaces';
import { ActionType } from 'app/store/actionTypes';
import { BonusRangeAndPointsInterface } from 'app/shared/interfaces/BonusRangeAndPoints.interface';

export const QuotationResultActions = {
  storeResults: (results: ResultsStoreActionPayload) =>
    createAction(ActionType.STORE_RESULTS, results),
  updateOfferAction: (offer: OfferUpdateActionPayload) =>
    createAction(ActionType.UPDATE_OFFER, offer),
  clearQuotationResult: () => createAction(ActionType.CLEAR_QUOTATION_RESULT),
  setCalculationTriggeredByUserAction: (triggeredByUser: boolean) =>
    createAction(ActionType.SET_CALCULATION_TRIGGERED_BY_USER, triggeredByUser),
  setPreviousQuotationDate: (data: {
    isPreviousQuotationSameDate: boolean;
    previousQuotationDate: string;
    isPreviousQuotationDateCheck: boolean;
  }) => createAction(ActionType.SET_PREVIOUS_QUOTATION_DATE, data),
  setRecommendedOfferIdAction: (id: string) =>
    createAction(ActionType.SET_RECOMMENDED_OFFER_ID, id),
  setSalesRewardBonusRangeAndPoints: (data: BonusRangeAndPointsInterface[]) =>
    createAction(ActionType.SET_SALES_REWARD_BONUS_POINTS, data),
};

export type QuotationResultActionsType = ActionsUnion<
  typeof QuotationResultActions
>;
