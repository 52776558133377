export enum RecommendedOfferEnum {
  Cheapest = 'Najtańsza',
  Extended = 'Rozszerzona',
  ExtendedOfferBadge = 'Rozszerzona oferta',
  Look = 'Zobacz',
  MaxInsuranceSumOfferBadge = 'Maksymalna oferta',
  Maximal = 'Maksymalna',
  MinPriceOfferBadge = 'Najtańsza oferta',
  MinPriceOfferTitle = 'Podstawowe zabezpieczenie',
  MubiExpertTitle1 = 'Poleca Paweł Kulig,',
  MubiExpertTitle2 = 'ekspert mubi',
  OfferForYou = 'Oferty dla Ciebie',
  OffersForYou = 'Propozycje ofert dla Ciebie',
  Optimal = 'Optymalna',
  OptimalOfferBadge = 'Optymalna oferta',
  Recommended = 'Polecana',
  RecommendedOfferBadge = 'Polecana oferta',
  WhyToChoose = 'Dlaczego warto wybrać tę ofertę?',
}

export enum SelectedRecommendationEnum {
  MaxInsuranceSumOffer = 'maxInsuranceSumOffer',
  MinPriceOffer = 'minPriceOffer',
  RecommendedOffer = 'recommendedOffer',
}

export enum RecommendationDetailTypeEnum {
  Luggage = 'LUGGAGE',
  MedicalExpenses = 'MEDICAL_EXPENSES',
  Nnw = 'NNW',
  Oc = 'OC',
}
