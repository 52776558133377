export enum BonusEnum {
  Bonus = 'Bonus: ',
  FriendsBuyPolicy = 'Twoi znajomi szukają polisy na\xa0Mubi i\xa0dokonują zakupu.',
  HowPromotionWorks1 = 'Bonus 100\xa0zł dla Ciebie i\xa0znajomego',
  HowPromotionWorks2 = ' za\xa0każdą opłaconą polisę z\xa0Twojego polecenia.',
  InviteFriends = 'Zaproś znajomych do Mubi udostępniając im Twój link lub kod.',
  Points = 'Punkty: ',
  YouCanRecommend = 'Odblokowałeś możliwość polecania i\xa0zarabiania.',
  YouCanStillRecommend = 'Masz nadal możliwość polecania i\xa0zarabiania z\xa0Mubi',
}
