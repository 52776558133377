export enum SectionsEnum {
  AdditionalDrivers = 'section.additionalDrivers',
  AdditionalDrivers1 = 'section.additionalDrivers.1',
  Assistance = 'section.assistance',
  CallbackSection1 = 'callback.section.1',
  Car1 = 'section.car.1',
  Car2 = 'section.car.2',
  CheckoutSectionCar = 'checkout.section.car',
  CheckoutSectionCarOwner = 'checkout.section.carOwner',
  CheckoutSectionCarOwnerHomeAddress = 'checkout.section.carOwner.homeAddress',
  CheckoutSectionCarOwnerRenewal = 'checkout.section.carOwnerRenewal',
  CheckoutSectionCoowners1 = 'checkout.section.coowners.1',
  CheckoutSectionCoowners1CorrespondenceAddress = 'checkout.section.coowners.1.correspondence.address',
  CheckoutSectionCoowners1HomeAddress = 'checkout.section.coowners.1.homeAddress',
  CheckoutSectionCoowners2 = 'checkout.section.coowners.2',
  CheckoutSectionCoowners2CorrespondenceAddress = 'checkout.section.coowners.2.correspondence.address',
  CheckoutSectionCoowners2HomeAddress = 'checkout.section.coowners.2.homeAddress',
  CheckoutSectionInsurant1 = 'checkout.section.insurant.1',
  CheckoutSectionInsurant2 = 'checkout.section.insurant.2',
  CheckoutSectionInsurant3 = 'checkout.section.insurant.3',
  CheckoutSectionInsurant4 = 'checkout.section.insurant.4',
  CheckoutSectionInsurant4Owner = 'checkout.section.insurant.4.owner',
  CheckoutSectionSkipFraudDetection = 'checkout.section.skipFraudDetection',
  CompareCepikDataSection1 = 'compareCepikData.section.1',
  CompareCepikDataSection2 = 'compareCepikData.section.2',
  Coowners1 = 'section.coowners.1',
  Coowners1InsuranceHistory = 'section.coowners.1.insuranceHistory',
  Coowners2 = 'section.coowners.2',
  Coowners2InsuranceHistory = 'section.coowners.2.insuranceHistory',
  DisableNotificationsReasonDifferentCarSection = 'disableNotifications.reason.differentCar.section',
  DisableNotificationsReasonOtherSection = 'disableNotifications.reason.other.section',
  DisableNotificationsReasonSection = 'disableNotifications.reason.section',
  EmailAddress = 'emailAddress.section',
  General = 'section.general',
  Glass = 'section.glass',
  Insurance = 'section.insurance',
  LeadSection1 = 'lead.section.1',
  LeasingLeadSectionCar1 = 'leasingLead.section.car.1',
  LeasingLeadSectionCar2 = 'leasingLead.section.car.2',
  LeasingLeadSectionInsurance = 'leasingLead.section.insurance',
  LesseeData = 'leasingLead.section.leeseeData',
  Nnw = 'section.nnw',
  OperatorLeadSection1 = 'operatorLead.section.1',
  Owner = 'section.owner',
  OwnerInsuranceHistory = 'section.owner.insuranceHistory',
  PaymentSectionConsents = 'payment.section.consents',
  PaymentSectionDocuments = 'payment.section.documents',
  PaymentSectionPayment = 'payment.section.payment',
  PaymentSectionPaymentMethod = 'payment.section.paymentMethod',
  PaymentSectionPromotionCode = 'payment.section.promotionCode',
  PersonalData = 'section.personalData',
  RecommendationSection1 = 'recommendation.section.1',
  RecoveryRequestSection = 'screen.recoveryRequest',
  RenewalRopSection = 'renewal.rop.section',
  ScreenCompleteData = 'section.complete_data',
  ShortCalculationPath = 'section.shortPath',
  SkpRegistrationSection1 = 'skpRegistration.section.1',
  SkpRegistrationSection2 = 'skpRegistration.section.2',
  SkpRegistrationSection3 = 'skpRegistration.section.3',
  SkpRegistrationSection4 = 'skpRegistration.section.4',
  SkpRegistrationSection5 = 'skpRegistration.section.5',
  SuspendedPolicyCoowner1HomeAddress = 'section.suspended_policy.coowner1.homeAddress',
  SuspendedPolicyCoowner1LegalAddress = 'section.suspended_policy.coowner1.legalAddress',
  SuspendedPolicyCoowner2HomeAddress = 'section.suspended_policy.coowner2.homeAddress',
  SuspendedPolicyCoowner2LegalAddress = 'section.suspended_policy.coowner2.legalAddress',
  SuspendedPolicyOwnerHomeAddress = 'section.suspended_policy.owner.homeAddress',
  SuspendedPolicyOwnerLegalAddress = 'section.suspended_policy.owner.legalAddress',
  TerminationGeneratorConsents = 'terminationGenerator.consents',
  TerminationGeneratorSection1 = 'terminationGenerator.section.1',
  TerminationGeneratorSection2 = 'terminationGenerator.section.2',
  TerminationGeneratorSection3 = 'terminationGenerator.section.3',
  TerminationGeneratorSection4 = 'terminationGenerator.section.4',
  TerminationGeneratorSection5 = 'terminationGenerator.section.5',
  TerminationGeneratorSection6 = 'terminationGenerator.section.6',
  TravelAllCurrentlyInPoland = 'travel.section.allCurrentlyInPoland',
  TravelCheckoutInsurantAdditional = 'checkout.section.travel.insurant.additional',
  TravelCheckoutInsurantConsents = 'checkout.section.insurant.consents',
  TravelCheckoutInsurantHomeAddress = 'checkout.section.travel.insurant.homeAddress',
  TravelCheckoutSectionContractSigner = 'checkout.section.travel.contract.signer',
  TravelCheckoutSectionInsurant = 'checkout.section.travel.insurant',
  TravelConsents = 'travel.consent.section',
  TravelDestination = 'travel.section.destination',
  TravelEmailAddress = 'travel.section.emailAddress',
  TravelGoal = 'travel.section.goal',
  TravelInsurancePeriod = 'travel.section.insurancePeriod',
  TravelTravelers = 'travel.section.travelers',
  TravelTravelers1 = 'section.travelers.0',
  TravelTravelers10 = 'section.travelers.9',
  TravelTravelers2 = 'section.travelers.1',
  TravelTravelers3 = 'section.travelers.2',
  TravelTravelers4 = 'section.travelers.3',
  TravelTravelers5 = 'section.travelers.4',
  TravelTravelers6 = 'section.travelers.5',
  TravelTravelers7 = 'section.travelers.6',
  TravelTravelers8 = 'section.travelers.7',
  TravelTravelers9 = 'section.travelers.8',
}
